@define-mixin contentPosition $desktopSpan: span 8, $tabletSpan: span 12 {
    grid-column: 1 / span 4;

    @media (--tablet) {
        grid-column: 1 / $tabletSpan;
    }
}

@define-mixin quoteVariant $background, $textColor, $borderColor, $iconColor: $textColor {
    background: $background;
    color: $textColor;

    & .Icon {
        @mixin svgIconColor $iconColor;
    }

    & .Content {
        border-color: $borderColor;
    }
}

.QuoteSection {
    @mixin studyPageSectionPadding;

    &.Variant--red {
        @mixin quoteVariant var(--primaryHKDarken20Color), var(--whiteBaseColor), var(--redDarken60LegacyColor),
            var(--redDarken60LegacyColor);
    }

    &.Variant--light {
        @mixin quoteVariant var(--whiteBaseColor), var(--blackBaseColor), var(--blackBaseColor);
    }

    &.Variant--dark {
        @mixin quoteVariant var(--blackBaseColor), var(--whiteBaseColor), var(--whiteBaseColor);
    }
}

.Inner {
    @mixin grid;
    grid-template-columns: repeat(4, 1fr);

    @media (--tablet) {
        grid-template-columns: repeat(12, 1fr);
    }
}

.Content {
    @mixin contentPosition;
    @mixin hyphenateWords manual;
    @mixin h40;
    padding-bottom: 32px;
    border-bottom: 2px solid;
    font-weight: 600;

    @media (--tablet) {
        @mixin h20;
        padding-bottom: 48px;
    }
}

.Employee {
    @mixin contentPosition span 6, span 10;

    @media (--tablet) {
        grid-row: 2;
    }
}

.IconWrapper {
    width: 100%;

    @media (--tablet) {
        display: flex;
        justify-content: flex-end;
        grid-column: 11 / span 2;
        grid-row: 2;
    }
}

.Icon {
    @mixin svgIcon 72px, 48px;

    @media (--tablet) {
        @mixin svgIcon 115px, 80px;
        margin: 0;
    }
}
