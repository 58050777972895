.QuoteEmployee {
    display: flex;
    position: relative;
    gap: 24px;

    & .Info,
    & .Link {
        display: flex;
        flex-direction: column;
    }
}

.Name {
    @mixin h50;
    @mixin hyphenateWords;
    font-weight: 600;

    @media (--tablet) {
        @mixin h40;
    }
}

.Image {
    @mixin aspectRatio 1;
    border-radius: 100%;
    overflow: hidden;
    width: 64px;
    height: 64px;

    @media (--tablet) {
        width: 72px;
        height: 72px;
    }
}

.Link {
    &::before {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.AdditionalInfo {
    @mixin p30;
    @mixin separateEveryChild ',', 0;
    display: flex;
    flex-direction: column;

    @media (--tablet) {
        @mixin h50;
    }
}

.JobTitles {
    @mixin clearList;
    @mixin separateEveryChild '/', 4px;
    display: inline;

    @media (--tablet) {
        margin-right: 4px;
    }
}

.JobTitle {
    @mixin hyphenateWords;
    display: inline;
}

.Department {
    display: inline-block;
}

.Variant--light {
    color: var(--whiteBaseColor);
}

.Variant--dark {
    color: var(--blackBaseColor);
}
